/**
 * Simple helper to toggle an element in a section.
 *
 * @type {(selection: string[], element: string) => string[]}
 */
export function toggleSelection(selection, element) {
    const set = new Set(selection)

    if (set.has(element)) {
        set.delete(element)
    } else {
        set.add(element)
    }

    // noinspection JSValidateTypes
    return Array.from(set.values())
}
