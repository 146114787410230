import {useEffect, useRef, useState} from "react";

export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function useDebounce(value, delay = 250) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)

            return () => {
                clearTimeout(handler);
            };
        },
        [JSON.stringify(value)]
    );

    return debouncedValue;
}

/**
 * @type {(value: string, throttle: number, onChangeCallback: (value: string) => any) => [string, (nextValue: string) => any]}
 */
export function useDebouncedChange(value = "", throttle = 250, onChangeCallback) {
    const [throttledValue, setThrottledValue] = useState(value)
    const debouncedOnChange = useRef(
        debounce(value => onChangeCallback(value), throttle)
    ).current

    useEffect(
        () => {
            setThrottledValue(value)
        },
        [value]
    )

    function handleChange(nextValue) {
        setThrottledValue(nextValue)

        if (nextValue !== throttledValue) {
            debouncedOnChange(nextValue)
        }
    }

    return [throttledValue, handleChange]
}
