import {Container} from "aurelia-dependency-injection";

const services = new WeakMap();

export const getService = service => {

    if (!services.has(service)) {
        services.set(service, Container.instance.get(service));
    }

    return services.get(service);
}

export default getService;
