/**
 * Much faster replacement for lodash's _.get function
 * @type {(object: any, path: string) => undefined|any}
 */
export default function getPropertyByPath(object, path, returnAsArray = false) {
    if (!object || !path) {
        return undefined
    }

    const parts = String(path).split(".");
    let wrap = true;

    // noinspection JSUnresolvedVariable
    let returnValue = parts.reduce((object, path, index) => {
        try {
            console.log('PPP', object, path, index);

            //only return for not last level
            if (Array.isArray(object)) {
                wrap = false;

                return object.map(function (e) {
                    return e[path];
                });
            }

            return object[path]
        } catch (e) {
            return undefined
        }
    }, object)

    if (returnAsArray && wrap && returnValue != null) {
        returnValue = [returnValue];
    }

    return returnValue;
}
