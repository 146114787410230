import {inject} from "aurelia-framework";
import {Router} from "aurelia-router";

@inject(Router)
export class UrlUtils {
    constructor(router) {
        this.router = router;
    }

    /**
     * @deprecated just use URL directly
     */
    static equal = (url1, url2) => new URL(url1).toString() === new URL(url2).toString();

    /**
     * @deprecated just use URL/URLParams directly.
     *
     * Extract query parameter value from url.
     *
     * @param {string} url Input url address
     * @param {string} paramName Query parameter name
     * @param {boolean} unpackParamValue If true, param will be parsed as BASE64 JSON
     * @returns {*}
     */
    static getQueryParamValue = (url, paramName, unpackParamValue = true) => {
        const value = new URL(url).searchParams.get(paramName);

        return value && unpackParamValue ? JSON.parse(atob(value)) : value;
    };

    /**
     * @deprecated just use method setParams, or URL/URLParams directly
     *
     * Upsert query parameter value in provided url, then return modified url.
     *
     * @param {string} url Input url address
     * @param {string} paramName Query parameter name
     * @param {*} paramValue Query parameter value to set
     * @param {boolean} packParamValue If true, param is stored as BASE64 JSON
     * @returns {string} Modified url address with upserted query parameter.
     */
    static upsertQueryParamValue = (url, paramName, paramValue, packParamValue = true) => {
        const urlObject = new URL(url);

        urlObject.searchParams.set(paramName, packParamValue ? btoa(JSON.stringify(paramValue)) : paramValue);

        return urlObject.toString();
    };

    /**
     * Sets one or more params on current location
     * @param {object|string} params
     * @param value only used when params is string
     */
    setParams = (params, value, reset) => this.router.navigateToRoute(
        this.router.currentInstruction.config.name,
        Object.assign(
            {},
            reset ? {} : Object.fromEntries(new URLSearchParams(document.location.search.substring(1)).entries()),
            this.router.currentInstruction.params,
            ("string" === typeof params) ? {[params]: value} : params
        ),
        //Important do not trigger update, we load view on our own
        {trigger: false, replace: false}
    );
}
