import {bindable, computedFrom} from "aurelia-framework";

export class Participant {
    @bindable assignment;

    @computedFrom('assignment')
    get participant() {
        if (!this.assignment) {
            return null;
        } else {
            return this.assignment
        }
    }
}
