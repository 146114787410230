import {LogManager} from "aurelia-framework";
import {UrlUtils} from "./url-utils.js";

const logger = LogManager.getLogger('WindowUtils');

export let WindowUtils = {
    updateRedirectLocationHref(newurl)
    {
        window.redirectLocationHref = newurl;
    },

    updateLocationHrefWithoutReloading(newurl)
    {
        // todo: Consider using aurelia-router instead of pushState on history
        // in same manner as in selected-tab-storage.js

        logger.debug('updateLocationHrefWithoutReloading', newurl);

        if (!UrlUtils.equal(newurl, window.location.href)) {
            logger.debug('updateLocationHrefWithoutReloading(): push new state to history');

            this.updateRedirectLocationHref(newurl);
            history.pushState({url: newurl}, '', newurl);
        }
    }
};
