import {Container} from "aurelia-dependency-injection";
import {AureliaConfiguration} from "aurelia-configuration";
import {AuthTokenStorage} from "../auth/auth-token-storage";
import {FlashService} from "../flash/flash-service";
import {I18N} from "aurelia-i18n";

/**
 * @type {(endpoint: string, body: any, setLoading: (boolean) => any, fileName: string, additionalParams: ?object) => any}
 */
export function doDownload(endpoint, body, setLoading, filename, additionalParams = {}) {
    setLoading(true)
    endpoint = new URL(Container.instance.get(AureliaConfiguration).get("apiUrl") + endpoint)

    for (const [key, value] of Object.entries(additionalParams)) {
        if ("number" === typeof value || ("string" === typeof value && "" !== value)) {
            endpoint.searchParams.set(key, value)
        }
    }

    const init = {
        method: body ? "PUT" : "GET",
        cache: "no-store",
        headers: {
            authorization: "Bearer " + Container.instance.get(AuthTokenStorage).getAccessToken(),
            "content-type": "application/json"
        }
    }

    if (body) {
        init.body = "object" === typeof body ? JSON.stringify(body) : body
    }

    fetch(endpoint.toString(), init).then(resp => {
        if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' === resp.headers.get('content-type')) {
            return resp.blob().then(blob => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = filename
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
            })
        } else {
            return resp.json().then(value => {
                if (true === value) {
                    return Container.instance.get(FlashService).success("sio.background-process")
                } else if (value?.message) {
                    return Promise.reject(value)
                }
            })
        }
    }).catch(e => {
        console.error(e)
        Container.instance.get(FlashService).error(Container.instance.get(I18N).tr(e?.message))
    }).finally(() => {
        setLoading(false)
    })
}
