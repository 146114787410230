export class asyncBindingBehavior
{
    bind(binding, source, busymessage)
    {
        binding.originalUpdateTarget = binding.updateTarget;
        binding.updateTarget = (a) => {
            if (typeof a.then === 'function') {
                if (busymessage) {
                    binding.originalUpdateTarget(busymessage);
                }

                a.then(d => {
                    if (binding.originalUpdateTarget) {
                        binding.originalUpdateTarget(d);
                    }
                });
            } else {
                binding.originalUpdateTarget(a);
            }
        };
    }

    unbind(binding)
    {
        binding.updateTarget = binding.originalUpdateTarget;
        binding.originalUpdateTarget = null;
    }
}
